exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kainodara-js": () => import("./../../../src/pages/kainodara.js" /* webpackChunkName: "component---src-pages-kainodara-js" */),
  "component---src-pages-kainodara-metinis-js": () => import("./../../../src/pages/kainodaraMetinis.js" /* webpackChunkName: "component---src-pages-kainodara-metinis-js" */),
  "component---src-pages-kontaktai-js": () => import("./../../../src/pages/kontaktai.js" /* webpackChunkName: "component---src-pages-kontaktai-js" */),
  "component---src-pages-straipsniai-js": () => import("./../../../src/pages/straipsniai.js" /* webpackChunkName: "component---src-pages-straipsniai-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

