import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import "../css/custom.css"
import FooterSection from "./FooterSection"
import FormSubmit from "./ContactForm"
import Header2 from "./NavBar"
import { withTrans } from '../i18n/withTrans'

const Layout = ({t, i18n , isHomePage, children }) => {
  // const {
  //   wp: {
  //     generalSettings: { title },
  //   },
  // } = useStaticQuery(graphql`
  //   query LayoutQuery {
  //     wp {
  //       generalSettings {
  //         title
  //         description
  //       }
  //     }
  //   }
  // `)

  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      <header className="global-header">
        {/* {isHomePage ? (
          
          <h1 className="main-heading">
            <Link to="/">{parse(title)}</Link>
          </h1>
        ) : (
          // Link comes from gatsby
          <Link className="header-link-home" to="/">
            {title}
          </Link>
        )}*/}
        <Header2></Header2>
      </header>

      <main>{children}</main>

      <footer id="kontaktai">
        <FooterSection />

        <br></br>

        <div className="footer_qa white_col">
          <h2>{t('footer.questions')}</h2>
          <p>
            {" "}
            {t('footer.postQuestions')}{" "}
          </p>

          <button
            className="button_blue"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#ContactForm"
            data-bs-whatever="@getbootstrap"
          >
            {t('footer.ctaButton')}
          </button>

          <div
            className="modal fade"
            id="ContactForm"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                  {t('footer.contactForm')}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <FormSubmit />
                {/*<div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Vardas:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      ></input>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        El. paštas:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      ></input>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="message-text" className="col-form-label">
                        Žinutė:
                      </label>
                      <textarea
                        className="form-control"
                        id="message-text"
                      ></textarea>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button type="button" className="button_blue">
                    Siųsti žinutę
                  </button>
                </div>*/}
              </div>
              
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center pt-4">
          © {new Date().getFullYear()} {t('footer.credit')}
          {` `}
          <a  className="cyan_link" href="https://chatmarketing.co/">ChatMarketing</a>
          {` `}
        </div>
      </footer>
    </div>
  )
}

export default withTrans(Layout)
