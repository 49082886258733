import React from "react"
import "../css/custom.css"
import "@fontsource/poppins"
import "@fontsource/montserrat"
import chatbotklinikomsmin from "../css/bgImages/chatbotklinikomsmin.jpg"
import contacts_01 from "../css/bgImages/contacts_01.svg"
import contacts_02 from "../css/bgImages/contacts_02.svg"
import contacts_03 from "../css/bgImages/contacts_03.svg"
import { withTrans } from '../i18n/withTrans'

const FooterSection = ({ children, t, i18n }) => {
  return (
    <>
      <div className="footer_img row ">
        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 describe_cm">
          <p>
            {t('footer.appMadeBy')} 
          </p>
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <img src={chatbotklinikomsmin} alt="img_5" />
        </div>
      </div>
      <h1>{t('footer.getInTouch')}</h1>
      <div className="row contact_footer d-flex justify-content-around">
        <div className="row col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 xs_mobile_row">
          <div className="img_contact col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-4">
            <img src={contacts_01} alt="soc_1" />
          </div>

          <div className="info_contact col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-8 ">
            <h5>{t('footer.call')}</h5>
            <p>+37068461657</p>
          </div>
        </div>
        <div className="row col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 xs_mobile_row">
          <div className="img_contact col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-4">
            <img src={contacts_02} alt="soc_2" />
          </div>

          <div className="info_contact col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-8">
            <h5>{t('footer.email')}</h5>
            <p>info@medboto.com</p>
          </div>
        </div>
        <div className="row col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 xs_mobile_row">
          <div className="img_contact col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-4">
            <img src={contacts_03} alt="soc_3" />
          </div>
          <div className="info_contact col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-8">
            <h5>{t('footer.visit')}</h5>
            <p>Savanorių pr. 276-396, Kaunas</p>
          </div>
        </div>
      </div>
      
    </>
  )
}
export default withTrans(FooterSection)
