// Should be in:  src/gatsby-plugin-apollo/client.js
import fetch from "isomorphic-fetch"
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: `https://backend.medboto.com/graphql`,
    fetch,
  }),
})
