// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
import "@fontsource/poppins"
import "@fontsource/montserrat"

// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/style.css"
import "./src/css/custom.css"
import "./src/css/new.css"

// additional frontend libraries
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "@popperjs/core/dist/umd/popper.min.js"

import React from "react"
import { ApolloProvider } from "@apollo/client"
import { client } from "./src/context/ApolloContext"

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
)
