import React, { useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { reference } from "@popperjs/core"
import { withTrans } from '../i18n/withTrans'

const CONTACT_MUTATION = gql`
  mutation(
    $clientMutationId: String!
    $emailAddress: String!
    $fullName: String!
    $message: String!
  ) {
    createSubmission(
      input: {
        clientMutationId: $clientMutationId
        emailAddress: $emailAddress
        fullName: $fullName
        message: $message
      }
    ) {
      success
      data
      clientMutationId
    }
  }
`
const FormSubmit = ({ children, t, i18n }) => {
  const [emailAddressValue, setEmailAddressValue] = useState("")
  const [fullNameValue, setFullNameValue] = useState("")
  const [messageValue, setMessageValue] = useState("")

  const [createSubmission, { loading, error, data }] = useMutation(
    CONTACT_MUTATION
  )

  return (
    <>
      <React.Fragment>
        <form
        className="contact_style"
          onSubmit={async event => {
            event.preventDefault()
            try {
              createSubmission({
                variables: {
                  clientMutationId: "example", // This should be user session ID - JWT?
                  emailAddress: emailAddressValue,
                  fullName: fullNameValue,
                  message: messageValue,
                },
              })
            } catch (err) {
              console.log(err)
            }
          }}
        >
          
          <label htmlFor="emailAddressInput">{t('form.email')}</label>
          <input
            id="emailAddressInput"
            value={emailAddressValue}
            onChange={event => {
              setEmailAddressValue(event.target.value)
            }}
          ></input>

          <br />

          <label htmlFor="fullNameInput">{t('form.name')}</label>
          <input
            id="fullNameInput"
            value={fullNameValue}
            onChange={event => {
              setFullNameValue(event.target.value)
            }}
          ></input>

          <br />

          <label htmlFor="messageInput">{t('form.message')}</label>
          <textarea
            id="messageInput"
            value={messageValue}
            onChange={event => {
              setMessageValue(event.target.value)
            }}
          ></textarea>

          <br />

          <button className="button_blue" type="submit">{t('form.send')}</button>
        </form>

        <div className="success_msg" style={{ padding: "20px" }}>
          {loading && <p>{t('form.sending')}</p>}
          {error && (
            <p>{t('form.failed')}</p>
          )}
          {data && <p>{t('form.success')}</p>}
        </div>
      </React.Fragment>
    </>
  )
}

export default withTrans(FormSubmit)
