import React, { useState, useEffect } from "react"
import logo_min from "../css/bgImages/logo_min.png"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"

/*
const navBar = document.querySelector('#navBararas');
window.onscroll = function ()  {
    if (document.body.scrollTop >= 200 || document.documentElement.scrollTop >= 200) {
        navBar.classList.add('scrollNavBar');
    } else {
        navBar.classList.remove('scrollNavBar');
    }
};*/

function Header2(props) {
  const [header, setHeader] = useState("header")

  const listenScrollEvent = event => {
    if (window.scrollY < 73) {
      return setHeader("header")
    } else if (window.scrollY > 70) {
      return setHeader("header2")
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent)

    return () => window.removeEventListener("scroll", listenScrollEvent)
  }, [])

  /*active */
  const { t, i18n } = useTranslation()

  const [values, setValues] = useState({
    language: "lt",
  })

  function handleChange(event) {
    console.log(event)
    i18n.changeLanguage(event)

    setValues(() => ({
      language: event,
    }))
  }

  const { languages, changeLanguage } = useI18next()

  return (
    <>
      <header className={header}>
        <nav
          className="navbar navbar-expand-lg navbar-light fixed-top container-lg mt-4"
          id="navBararas"
        >
          <div className="navbar_layout ">
            <div className="col-xxl-6 col-xl-6 col-lg-6 nav_img">
              <a className="navbar_logo" href="/">
                <img src={logo_min} alt="logo" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div
              className="collapse navbar-collapse nav_menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/#pradzia"
                    activeStyle={{
                      color: "#6dd3c6",
                    }}
                    activeClassName="active"
                  >
                    {t('header.navLinkStart')}
                  </Link>
                </li>

                <li>
                  <Link
                    className="nav-link"
                    to="/#nauda"
                    activeStyle={{
                      color: "#6dd3c6",
                    }}
                    activeClassName="active"
                  >
                    {t('header.navLinkBenefits')}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/#kanalai"
                    activeStyle={{
                      color: "#6dd3c6",
                    }}
                    activeClassName="active"
                  >
                    {t('header.navLinkChannels')}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/#funkcijos"
                    activeStyle={{
                      color: "#6dd3c6",
                    }}
                    activeClassName="active"
                  >
                    {t('header.navLinkFunctions')}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/kainodara"
                    activeStyle={{
                      color: "#6dd3c6",
                    }}
                    activeClassName="active"
                  >
                    {t('header.navLinkPrice')}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/straipsniai"
                    activeStyle={{
                      color: "#6dd3c6",
                    }}
                    activeClassName="active"
                  >
                    {t('header.navLinkBlog')}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/#kontaktai"
                    activeStyle={{
                      color: "#6dd3c6",
                    }}
                    activeClassName="active"
                  >
                    {t('header.navLinkContacts')}
                  </Link>
                </li>
                {/*
            <li>
            <Link className="flag_en nav-link" 
            to="/"
            >EN</Link>
            </li>
          </ul>
          <ul className="languages">
        
          <Select
      value={values.language}
      onChange={(e) => handleChange(e)}
      disableUnderline
      inputProps={{
        name: 'language'
      }}
    >
      <MenuItem value={'en'}>EN</MenuItem>
      <MenuItem value={'lt'}>LT</MenuItem>
    </Select>*/}
                {languages.map(lng => (
                  <li className="nav-link language_switch" key={lng}>
                    <a
                      href="#"
                      value={lng}
                      onClick={e => {
                        e.preventDefault()
                        handleChange(lng)
                      }}
                    >
                      {lng}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header2
